import {EventEmitter, Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {TrackerService} from './tracker.service';
import {SessionService} from './session.service';

@Injectable()
export class NavigatorService {

    currentPage: string = 'home';
    currentParams: any = {};
    currentTimeStamp: Date;

    navigationHistory: any = [];

    constructor(
        private router: Router,
        private sessionService: SessionService,
        private trackerService: TrackerService
    ) {
        this.sessionService.sessionIdChange.subscribe((sessionId) => {
            if (sessionId === '') {
                this.resetNavigation();
            }
        })
    }

    resetNavigation() {
        this.currentPage = 'home';
        this.currentParams = {};
        this.navigationHistory = [];
        this.router.navigate(['home']).then();
        console.log('navigation history reset');
    }

    navigate(page, parameters) {
        let timeSpent = 0;
        if (this.currentTimeStamp) {
            timeSpent = new Date().getTime();
            timeSpent -= this.currentTimeStamp.getTime();
        }

        if (!Array.isArray(page)) {
            page = [page];
        }

        this.navigationHistory.push({
            from: {
                page: this.currentPage,
                params: this.currentParams,
                timeSpent: timeSpent
            },
            to: {
                page: page,
                params: parameters
            }
        });

        this.router.navigate(page, parameters).then(() => {
            this.trackerService.trackNavigation('fat-test|' + page, parameters);
            this.sessionService.logActivity();
            this.currentParams = parameters;
            this.currentPage = page;
            this.currentTimeStamp = new Date;
        });

        console.log(this.navigationHistory);
    }



}