import {Routes} from '@angular/router';


export const Full_ROUTES: Routes = [
    {
        path: 'admin-test-panel',
        loadChildren: './admin-test-panel/admin-test-panel.module#AdminTestPanelModule'
    },
    {
        path: 'tests',
        loadChildren: './tests/tests.module#TestsModule'
    },
    {
        path: 'sat-test',
        loadChildren: './sat-test/sat-test.module#SatTestModule'
    },
    {
        path: 'home',
        loadChildren: './home/home.module#HomeModule'
    },
];
