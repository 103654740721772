import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {StatusService} from "./status.service";

@Injectable()
export class MiniPcBackEndService {
    backendUrl: string = environment.apiUrl;


    constructor(private http: HttpClient, private statusService: StatusService) {
    }

    openDefibrillator() {
        const url = this.backendUrl + '/defibrillators/1?action=openDefibrillator';
        return this.http.post(url, {});
    }

    switchOffPanicButtonLight() {
        return this.http.post(`${this.backendUrl}/emergency-buttons/1?action=switchOffPanicButtonLight`, {});
    }

    setLed(id, dmxLed) {
        console.log(dmxLed);
        const url = this.backendUrl + '/leds/' + id + '?action=setLedColour';
        return this.http.post(url, {rgbw: dmxLed});
    }

    getStatus() {
        return this.http.get(`${this.backendUrl}/status`);
    }

    getKioskConfig() {
        return this.http.get(`${this.backendUrl}/config`);
    }


    saveUid(uid) {
        const url = this.backendUrl + '/kiosks/my/?action=set-uid'
        return this.http.post(url, {uid:uid});
    }


    sendFatTest(data) {
        const url = this.backendUrl + '/kiosks/my/fat-test?action=store-fat-test';
        return this.http.post(url, data);
    }

    sendSatTest(data) {
        const url = this.backendUrl + '/kiosks/my/sat-test?action=store-sat-test';
        return this.http.post(url, data);
    }


    getFatTestStatus() {
        return this.http.get(`${this.backendUrl}/tests/fat-test/`);
    }

    startFatTest(test) {
        return this.http.post(`${this.backendUrl}/tests/fat-test?action=start-test`, test);
    }

    saveFatTest(test) {
        return this.http.post(`${this.backendUrl}/tests/fat-test?action=save-test`, test);
    }

    resetFatTest() {
        return this.http.post(`${this.backendUrl}/tests/fat-test?action=reset-test`, {});
    }

    finishFatTest(test) {
        return this.http.post(`${this.backendUrl}/tests/fat-test?action=finish-test`, test);
    }


    getInstallationTestStatus() {
        return this.http.get(`${this.backendUrl}/tests/installation-test/`);
    }

    startInstallationTest(test) {
        return this.http.post(`${this.backendUrl}/tests/installation-test?action=start-test`, test);
    }

    saveInstallationTest(test) {
        return this.http.post(`${this.backendUrl}/tests/installation-test?action=save-test`, test);
    }

    resetInstallationTest() {
        return this.http.post(`${this.backendUrl}/tests/installation-test?action=reset-test`, {});
    }

    finishInstallationTest(test) {
        return this.http.post(`${this.backendUrl}/tests/installation-test?action=finish-test`, test);
    }

    sendVideoFile(file) {
        const input = new FormData();
        input.append('file', file);
        return this.http.post(`${this.backendUrl}/videos`, input);

    }
}
