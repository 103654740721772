import {EventEmitter, Injectable} from '@angular/core';
import {StatusService} from './status.service';
import {ajaxGetJSON} from 'rxjs/internal-compatibility';

declare const SIPml: any;
declare const Twilio: any;

@Injectable()
export class PhoneService {

    MIN_PHONE_NUMBER_LENGTH = 10;
    MAX_PHONE_NUMBER_LENGTH = 11;
    MAX_PHONE_TIME_MINUTE = 10;

    CALL_STATE_NOT_STARTED = 0;
    CALL_STATE_KEY_STARTED = 10;
    CALL_STATE_READY_FOR_CALL = 20;
    CALL_STATE_CONNECTING = 30;
    CALL_STATE_CONNECTED = 40;
    CALL_STATE_HANGED_UP = 50;
    CALL_STATE_TERMINATED = 60;
    CALL_STATE_TIMED_OUT = 70;

    STATUS_ONLINE = 'connected';
    STATUS_NOT_AVAILABLE = 'disconnected';

    sipStack: any;
    registerSession: any;
    callSession: any;
    callState: number = 0;
    callTimerHandler: any;
    timerSeconds: number = 0;
    timerMinutes: number = 0;
    callTime: string = '00:00';
    callTimeTotalSecs: number = 0;
    phoneBookEntry: any = {
        name: '',
        number: '',
    }
    status: any;

    constructor(private statusService: StatusService) {
         this.statusService.setPhoneStatus({
              connectionStatus: this.STATUS_NOT_AVAILABLE
          });
          this.status = this.STATUS_NOT_AVAILABLE;
          this.sipStack = new SIPml.Stack({
                  realm: 'sip02.mtx.cloud',
                  impi: 'umbi1750-201',
                  impu: 'sip:umbi1750-201@sip02.mtx.cloud',
                  password: 'ZCnlcmnClj',
                  display_name: 'MTX Test',
                  websocket_proxy_url: 'wss:sip02.mtx.cloud:8089/ws',
                  ice_servers: [{
                      url: 'stun:stun.l.google.com:19302'
                  }, {
                      url: 'turn:turn.mtx.cloud:5349?transport=tcp',
                      credential: 'sid8fh6dg',
                      username: 'umbicord'
                  }],
                  enable_rtcweb_breaker: false,
                  sip_headers: [ // optional
                      {name: 'User-Agent', value: 'MTX Demo Softphone Sample Code'},
                      {name: 'Organization', value: 'MTX Cloud Services LTD'}
                  ]
              }
          );


          this.sipStack.addEventListener('*', (e) => {
              console.info('Start: Change of status|Server response: ' + e.type + ':' + e.message + ':' + e.session + ':' + e.description);
              if (e.type == 'started') {
                  this.login();
              } else if (e.type == 'stopped') {
                  this.sipStack.start();
              }
          });
          this.sipStack.start();
        /*
        ajaxGetJSON('https://prune-guanaco-7331.twil.io/capability-token')
            .subscribe((data => {
                console.log(data);


                Twilio.Device.setup(data['token']);

                Twilio.Device.ready((device) => {
                    console.log('Twilio.Device Ready!');
                });
            }));*/
    }

    login() {
        this.registerSession = this.sipStack.newSession('register');
        this.registerSession.addEventListener('*', (e) => {
            console.info('Login: Register Change of status|Server response: ' + e.type + ':' + e.message + ':' + e.session + ':' + e.description);
            if (e.type == 'connected') {
                if (e.session == this.registerSession) {
                    this.status = this.STATUS_ONLINE;
                    this.statusService.setPhoneStatus({
                        connectionStatus: this.STATUS_ONLINE
                    });
                } else {
                    console.log(e.type, e.description);
                }
            }
        });
        this.registerSession.register();
    }

    acceptMessage(e) {
        console.log('accept Message');
    }

    acceptCall(e) {
        console.log('accept Call');
    }

    resetCall() {
        this.timerSeconds = 0;
        this.timerMinutes = 0;
        this.callTimeTotalSecs = 0;
        this.callState = 0;
        this.callTime = '00:00';
        this.phoneBookEntry = {
            number: '',
            name: ''
        };
        console.log(this.phoneBookEntry);
        this.stopCountTime();
    }

    startCall() {
        if (this.callState != 20) {
            return;
        }
        this.timerSeconds = 0;
        this.timerMinutes = 0;
        this.callTimeTotalSecs = 0;
        this.callTime = '00:00';
        this.callSession = this.sipStack.newSession('call-audio', {
            audio_remote: document.getElementById('audio-remote'),
        });
        this.callSession.addEventListener('*', (e) => {
            console.info(' StartCall : Call Change of status|Server response: ' + e.type + ':' + e.message + ':' + e.session + ':' + e.description);
            if (e.type == 'terminated') {
                this.terminatedCall();
            }
        });
        this.callSession.call(this.phoneBookEntry.number);

        /*
        Twilio.Device.connect(this.phoneBookEntry.number);
        */
        this.callState = 40;
        this.countTime();
    }

    hangupCall() {
        if (this.callSession) {
            this.callSession.hangup();
        }
        this.callState = this.CALL_STATE_HANGED_UP;
        this.stopCountTime();
    }

    terminatedCall() {
        this.callState = this.CALL_STATE_TERMINATED;
        this.stopCountTime();
    }

    setPhonebookEntry(phonebookEntry) {
        this.phoneBookEntry = phonebookEntry;

        this.callState = 0;

        let minimumLength =  this.MIN_PHONE_NUMBER_LENGTH;
        if (this.phoneBookEntry.number.slice(0,1) == 0) {
            minimumLength++;
        }

        if (this.phoneBookEntry.number.length >= minimumLength) {
            this.callState = 20;
        } else if (this.phoneBookEntry.number.length > 0) {
            this.callState = 10;
        }
    }

    increaseTime() {
        let zero: any = '';
        let zeroMin: any = '';
        this.callTimeTotalSecs++;
        this.timerSeconds = this.timerSeconds + 1;
        if (this.timerSeconds % 60 === 0) {
            this.timerSeconds = 0;
            this.timerMinutes = this.timerMinutes + 1;
        }

        if (this.timerMinutes < 10) {
            zeroMin = 0;
        }

        if (this.timerSeconds < 10) {
            zero = 0;
        }

        this.callTime = `${zeroMin}${this.timerMinutes}:${zero}${this.timerSeconds}`;
    }


    countTime() {
        this.callTimerHandler = setTimeout(() => {

            this.increaseTime();

            if (this.timerMinutes < 10) {
                this.countTime();
                return;
            }

            if (this.callState == 40 || this.callState == 50) {
                this.hangupCall();
                return;
            }

        }, 1000);
    }

    stopCountTime() {
        clearTimeout(this.callTimerHandler);
    }
}