import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TestService {

    backendUrl: string = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    startTest() {
        const url = `${this.backendUrl}/tests/start-test`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testVideo() {
        const url = `${this.backendUrl}/tests/video`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testVideoHeadset() {
        const url = `${this.backendUrl}/tests/video-headset`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testCall() {
        const url = `${this.backendUrl}/tests/call`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testLeftSpeaker() {
        const url = `${this.backendUrl}/tests/speaker/left`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testRightSpeaker() {
        const url = `${this.backendUrl}/tests/speaker/right`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testIcons() {
        const url = `${this.backendUrl}/tests/icons`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testTopLight() {
        const url = `${this.backendUrl}/tests/top-light`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testDoorSensors() {
        const url = `${this.backendUrl}/tests/door-sensors`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testDefibrillatorSensor() {
        const url = `${this.backendUrl}/tests/defibrillator-sensor`;
        return this.http.post(url, {}).subscribe(() => {});
    }

    testEmergencyButton() {
        const url = `${this.backendUrl}/tests/emergency-button`;
        return this.http.post(url, {}).subscribe(() => {});
    }

    testNFC() {
        const url = `${this.backendUrl}/tests/nfc`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testUsbOne() {
        const url = `${this.backendUrl}/tests/usb-one`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testUsbTwo() {
        const url = `${this.backendUrl}/tests/usb-two`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testUsbThree() {
        const url = `${this.backendUrl}/tests/usb-three`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testTouchScreen() {
        const url = `${this.backendUrl}/tests/touch-screen`;
        return this.http.post(url, {}).subscribe( () => {});
    }

    testFinish() {
        const url = `${this.backendUrl}/tests/finish`;
        return this.http.post(url,{}).subscribe(() => {});
    }

    testSaveTouchScreen() {
        const url = `${this.backendUrl}/tests/save-touch-screen`;
        return this.http.post(url, {});
    }
}
