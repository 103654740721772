import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import {AuthService} from "../auth/auth.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {
    currentLang = 'en';
    toggleClass = 'ft-maximize';

    constructor(public translate: TranslateService,
                private router: Router,
                private userService: UserService,
                private authService: AuthService) {
        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
    }

    ngOnInit() {
       /* this.authService.logout();
        this.router.navigate(['/login']);
        this.userService.clearUser();
        this.router.navigateByUrl('/home');*/
    }

    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }

    logout() {
        this.userService.clearUser();
        this.router.navigateByUrl('/login');
    }
}
