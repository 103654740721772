import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    userUpdated: EventEmitter<any> = new EventEmitter();
    user: any = {};
    token: string = null;

    constructor() {
        this.user = this.getUser();
    }

    setUser(user) {
        this.user = user;
        this.token = user.apiToken;

        /*localStorage.setItem('apiToken', user['apiToken']);
        localStorage.setItem('user', JSON.stringify(user));*/
    }

    getUser() {
        if (this.user.id) {
            return this.user;
        }

        const user = localStorage.getItem('user');
        if (user) {
            const userObject = JSON.parse(user);
            if (userObject.id) {
                this.user = userObject;
                return this.user;
            }
        }

        return {};
    }

    clearUser() {
        this.user = {};
        localStorage.clear();
        this.userUpdated.emit(this.user);
    }

    getToken = function () {
        if (this.token) {
            return this.token;
        }
        return localStorage.getItem('apiToken');
    }

    getStartUrl() {
        if (!this.user.id) {
            return '/login';
        } else if (this.user.status === 1) {
            return '/register';
        } else if (this.user.status === 2) {
            return '/home';
        } else {
            return '/';
        }
    }
}
