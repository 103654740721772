import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    latestStatus: any = {};

    constructor(private authService: AuthService,
                private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.data.unAuthenticated) {
            return true;
        }

        if (!this.authService.isAuthenticated()) {
            this.router.navigateByUrl('/login')
                .then();
            return false;
        }
        return true;
    }
}
