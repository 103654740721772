import {EventEmitter, Injectable} from '@angular/core';
import {v4 as uuid} from 'uuid';
import {Subject} from "rxjs/index";
@Injectable()
export class SessionService {
    sessionTimerHandler: any;
    sessionTime: number = 0;
    sessionId: string = '';


    sessionIdChange: Subject<string> = new Subject<string>();

    inactivityTimerHandler: any;
    inactivityTime: number = 0;

    constructor() {

    }

    createNewSession() {
        if (this.sessionId) {
            return;
        }
        this.sessionId = uuid();
        this.countSessionTime();
        this.countInactivityTime();
        this.sessionIdChange.next(this.sessionId);
    }

    resetSession() {
        this.inactivityTime = 0;
        this.sessionTime = 0;
        this.sessionId = '';
        clearTimeout(this.sessionTimerHandler);
        clearTimeout(this.inactivityTimerHandler);
        this.sessionIdChange.next(this.sessionId);
    }

    countSessionTime() {
        this.sessionTimerHandler = setTimeout(() => {

            this.sessionTime++;

            if (this.sessionTime % 10) {
                //console.log('sessiontime', this.sessionTime);
            }
            this.countSessionTime();
        }, 100);
    }

    countInactivityTime() {
        this.inactivityTimerHandler = setTimeout(() => {

            this.inactivityTime++;
            if (this.inactivityTime % 5) {
                console.log('inactivity', this.inactivityTime)
            }

            if (this.inactivityTime > 10) {
//                this.resetSession();
                return;
            }
            this.countInactivityTime();
        }, 1000);
    }

    logActivity() {
        this.inactivityTime = 0;
    }
}