import {Component, OnInit} from '@angular/core';
import {ROUTES} from './sidebar-routes.config';
import {RouteInfo} from "./sidebar.metadata";
import {Router, ActivatedRoute} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../services/user.service";

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];

    public user: any;

    constructor(private router: Router,
                private route: ActivatedRoute,
                public translate: TranslateService,
                private userService: UserService) {

    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.user = this.userService.getUser();
    }

    //NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf('forms/ngx') !== -1)
            this.router.navigate(['forms/ngx/wizard'], {skipLocationChange: false});
    }
}
