import { Injectable } from '@angular/core';
import {UserService} from '../../services/user.service';

@Injectable()
export class AuthService {
    token: string;
    user: any = {};



    constructor(private userService: UserService) {}

    signupUser(email: string, password: string) {
        //your code for signing up the new user
    }

    signinUser(email: string, password: string) {
        //your code for checking credentials and getting tokens for for signing in user
    }

    logout() {
        this.token = null;
    }

    getToken() {
        return this.token;
    }

    isAuthenticated() {
        // here you can check if user is authenticated or not through his token
        this.user = this.userService.getUser();
        if (this.user.id) {
            return true;
        }

        return false;

    }
}
