// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    // apiUrl: 'http://192.168.1.8:3000'
    // apiUrl: 'http://api.pulse.localdev',
    // apiUrl: 'http://api.pulse.umbicord.com'
    // apiUrl: 'http://192.168.1.24:3000'
    // apiUrl: 'http://192.168.0.13:3000'
    // apiUrl: 'http://192.168.0.29:3000'
    // apiUrl: 'http://192.168.0.13:3000'
    // apiUrl: 'http://localhost:3000'
    apiUrl: 'http://192.168.1.5:3000'
}
