import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KioskService {


  backendUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {
  }


  getKiosks(filters, orderBy, page, totalPerPage) {
    const httpParam = new HttpParams()
      .set('page', page)
      .set('totalPerPage', totalPerPage)
      .set('filters', JSON.stringify(filters));
     // .set('orderBy', JSON.stringify(orderBy));

    const url = this.backendUrl + '/kiosks';

    return this.http.get(url, {params: httpParam });
  }

  getKiosk(id) {
    const url = this.backendUrl + '/kiosks/' + id;
    return this.http.get(url);
  };

  powerOff() {
    const url = 'http://192.168.0.77/?action=kiosk-off';
    return this.http.get(url);
  }

  powerOn() {
    const url = 'http://192.168.0.77/?action=kiosk-on';
    return this.http.get(url);
  }
}
