import { Routes } from '@angular/router';


export const CONTENT_ROUTES: Routes = [
    {
        path: 'login',
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'wait-for-pulse-response',
        loadChildren: './wait/wait.module#WaitModule',
        data: {unAuthenticated: true}
    },
];
