import {Injectable} from "@angular/core";
import {Subject} from "rxjs/index";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {KioskStatus} from "../types/KioskStatus";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class StatusService {

    status: KioskStatus = {
        sensorBoard: {
            connectionStatus: 'disconnected',
            stage: null,
            lock: null,
            emergencyButton: null,
            defibrillator: null,
            defibrillatorDoor: null,
            frontDoor: null,
            backDoor: null,
            sideDoor: null,
            lastResponse: null
        },
        dmx: {
            connectionStatus: 'disconnected',
        },
        server: {
            connectionStatus: 'disconnected',
            connectionRetryTime: null
        },
        nfc: {
            connectionStatus: 'disconnected'
        },
        phone: {
            connectionStatus: 'disconnected'
        },
        enviroWatch: {
            connectionStatus: 'disconnected'
        },
        kioskStatus: 'notStarted'

    };


    statusChange: Subject<KioskStatus> = new Subject<KioskStatus>();

    backendUrl: string = environment.apiUrl;

    timeoutHandler: any = null;

    observableStatus: any;

    constructor(private http: HttpClient) {
        this.observableStatus = new BehaviorSubject(this.status);
        this.syncStatus();
    }

    syncStatus() {

        //console.log(this.status);
        this.getStatus()
            .subscribe((status:KioskStatus) => {
                this.status.sensorBoard = status.sensorBoard;
                this.status.dmx = status.dmx;
                this.status.server = status.server;
                this.status.nfc = status.nfc;
                this.status.kioskStatus = status.kioskStatus;
                this.observableStatus.next(this.status);
                this.statusChange.next(this.status);
                //console.log(this.status);
                this.timeoutHandler = setTimeout(() => {
                    this.syncStatus();
                }, 500);
            }, () => {
                this.timeoutHandler = setTimeout(() => {
                    this.syncStatus();
                }, 5000);
            });
    }

    setPhoneStatus(phoneStatus) {
        this.status.phone = phoneStatus;
        this.observableStatus.next(this.status);
        this.statusChange.next(this.status);
    }


    getStatus() {
        return this.http.get(`${this.backendUrl}/status`);
    }

    stopSync() {
        clearTimeout(this.timeoutHandler);
    }

}