import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SessionService} from "./session.service";

@Injectable()
export class TrackerService {

    backendUrl: string = environment.apiUrl;

    constructor(private sessionService: SessionService, private http: HttpClient) {

    }

    trackClick(page, eventName, eventParameters) {
        this.sessionService.logActivity();
        const url = this.backendUrl + '/trackers/click';
        const data = {
            page: page,
            eventName: eventName,
            eventParameters: eventParameters,
            sessionId: this.sessionService.sessionId
        };
        return this.http.post(url, data).subscribe( () => {});
    }
    trackNavigation(page, parameters) {

        const url = this.backendUrl + '/trackers/navigation';
        const data = {
            page:page,
            eventName: 'navigate',
            eventParameters: parameters,
            sessionId: this.sessionService.sessionId
        };
        return this.http.post(url, data).subscribe( () => {});
    }


}